import React, { useEffect, useState } from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";

import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { CardsThree } from "../homepage";

const cardOne = require("../../assets/images/homepage/group1.png");
const cardTwo = require("../../assets/images/homepage/group4.png");

export const icon1 = require("../../assets/img/how-it-works/hw_sec_1_icon.png");
export const icon2 = require("../../assets/img/how-it-works/hw_sec_2_icon.png");
export const icon3 = require("../../assets/img/how-it-works/hw_sec_3_icon.png");

const section_one = require("../../assets/img/how-it-works/hw_sec_1.png");
const section_two = require("../../assets/img/how-it-works/hw_sec_2.png");
const section_three = require("../../assets/img/how-it-works/hw_sec_3.png");

export const cardData = [
  {
    image: cardOne,
    header: "Workativ for Conversational IT Support",
    content: `Deliver faster and superior IT Support, powered by Conversational AI and Automation. Augment your IT Help Desk to scale your support easily in a few clicks. `,
    content_1: `No coding required.`,
    linkAddress: "/assistant/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for Conversational HR Support",
    content: `Transform HR experience for employees with our AI-powered chatbots with prebuilt HR process automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/conversational-hr-support",
    backgroundClass: "bg_card_2",
  },
];

const featureContent = [
  {
    icon: icon1,
    header: "Simple, and easy to use chatbot builder canvas",
    content:
      "Build awesome dialogs and deliver engaging end-user experiences using workativ assistant’s chatbot builder and dialog management functionality. Be the conversational ai champion for your team and unlock your creative dialog building skills. No coding.",
    url: "/conversational-ai-platform/chatbot-builder",
    link: "More on chatbot builder  ",
    image: section_one,
    alt: "chatbot builder",
    id: "section-1",
  },
  {
    icon: icon2,
    header: "Add pre-built workflow automations",
    content:
      "Integrate chatbot with various apps in a click and add workflows to chatbot using our no-code workflow automation builder in workativ assistant at no additional cost.",
    url: "/conversational-ai-platform/chatbot-automation",
    link: "More on chatbot automation  ",
    image: section_two,
    alt: "pre-built automation for chatbot",
    id: "section-2",
  },
  {
    icon: icon3,
    header: "Deploy on Slack or Teams",
    content:
      "The final step to go live is to deploy your chatbot on your chat apps like MS Teams or Slack in few clicks, so your employees can quickly self-resolve their workplace issues instantly.",
    url: "/conversational-ai-platform/slack-chatbot",
    link: "More on slack and teams",
    image: section_three,
    alt: "deploy chatbot on chat apps",
    id: "section-3",
  },
];
const ldSchema = {
  "@context": "https://schema.org/",

  "@type": "HowTo",

  name: "How workativ Assistant Works",

  description:
    "Go live with an advanced chatbot with process automation in 3 steps. No coding, we promise!",

  step: [
    {
      "@type": "HowToStep",

      text: "Build Dialogs",

      url: "https://workativ.com/assistant/how-it-works#section-1",
    },

    {},
    {
      "@type": "HowToStep",

      text: "Automate Process",

      name: "Automation Designer",

      url: "https://workativ.com/assistant/how-it-works#section-2",
    },
    {
      "@type": "HowToStep",

      text: "Deploy",

      name: "Deploy",

      url: "https://workativ.com/assistant/how-it-works#section-3",
    },
  ],
};
export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="How it works | Free Conversational AI Chatbot"
        description="Learn how to build a chatbot in three simple steps. Sign up now for free."
        keywords={["workativ how it works"]}
        ogImage={section_one}
        ogTitle="How it works | Free Conversational AI Chatbot"
        ogDescription="Learn how to build a chatbot in three simple steps. Sign up now for free."
        schema={ldSchema}
      />
      <Container>
        <Layout backgroundColor={"how_it_section_bg"} logoFor="ASSISTANT">
          <HowItsWorkPage />
          <div className="how_works_wrapper">
            {featureContent.map((feature, index) => {
              if (index % 2 !== 0) {
                return (
                  <div className="leftcontent-wrapper">
                    <RightImageWithContentFeature
                      image={feature.image}
                      altImage={feature.alt}
                      iD={feature.id}
                    >
                      <RightImageWithContentFeature.Header>
                        <div className="icon-top-img">
                          <img src={feature.icon} alt="icon" />{" "}
                        </div>
                        <h3>{feature.header}</h3>
                      </RightImageWithContentFeature.Header>

                      <RightImageWithContentFeature.SubHeader>
                        <p>{feature.content}</p>
                      </RightImageWithContentFeature.SubHeader>
                      <RightImageWithContentFeature.Link>
                        <div className="card_link_landing">
                          <a href={feature.url} className="url_manipulation">
                            {feature.link}
                            <span className="arrow_svg_link">
                              <ArrowRightICon />
                            </span>
                          </a>
                        </div>
                      </RightImageWithContentFeature.Link>
                    </RightImageWithContentFeature>
                  </div>
                );
              }
              return (
                <div className="leftimage-wrapper">
                  <LeftImageWithContent
                    image={feature.image}
                    altImage={feature.alt}
                    iD={feature.id}
                  >
                    <LeftImageWithContent.HeaderIcon>
                      <div className="icon-top-img">
                        <img src={feature.icon} alt="icon" />{" "}
                      </div>
                    </LeftImageWithContent.HeaderIcon>

                    <LeftImageWithContent.MainHeader>
                      {feature.header}
                    </LeftImageWithContent.MainHeader>
                    <LeftImageWithContent.SubHeader>
                      <p>{feature.content}</p>
                    </LeftImageWithContent.SubHeader>
                    <LeftImageWithContent.Link>
                      <div className="card_link_landing jira_landing_link how_ts_link">
                        {feature.link && (
                          <a href={feature.url} className="url_manipulation">
                            {feature.link}
                            <span className="arrow_svg_link">
                              <ArrowRightICon />
                            </span>
                          </a>
                        )}
                        {feature.link1 && (
                          <a href={feature.url1} className="url_manipulation">
                            {feature.link1}
                            <span className="arrow_svg_link">
                              <ArrowRightICon />
                            </span>
                          </a>
                        )}
                      </div>
                    </LeftImageWithContent.Link>
                  </LeftImageWithContent>
                </div>
              );
            })}
          </div>
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}

function HowItsWorkPage() {
  return (
    <section className="how_it_section how_it_page_content">
      <div className="how_it_section_page">
        <div className="container">
          <div className="main-slider-left">
            <h1>
              Only workativ assistant offers both conversational ai and workflow
              automation in single platform
            </h1>
          </div>
          <div className="row">
            {/*How it Works Content*/}
            <div className="x_scroll_page">
              <div className="how_it_works_header">
                <div className="benefits-workplace-content how_it_content how_it_content_before">
                  <img
                    src={require("../../assets/img/how-it-works/step_1.png")}
                    alt="build"
                  />
                  <h3>
                    <AnchorLink
                      offset={180}
                      className="url_manipulation scroll_page"
                      href="#section-1"
                    >
                      <span>01.</span>Build Dialogs
                    </AnchorLink>
                    {/* <a className="scroll_page" href="#section-1">
                     
                    </a> */}
                  </h3>
                  <p>
                    Build engaging dialogs using simple and easy to use no-code
                    chatbot canvas.
                  </p>
                </div>
              </div>
              {/*How it Works Content*/}
              <div className="how_it_works_header">
                <div className="benefits-workplace-content  how_it_content how_it_content_before">
                  <img
                    src={require("../../assets/img/how-it-works/step_2.png")}
                    alt="automate"
                  />
                  <h3>
                    <AnchorLink
                      offset={180}
                      className="url_manipulation scroll_page"
                      href="#section-2"
                    >
                      <span>02.</span>Add workflows
                    </AnchorLink>
                  </h3>
                  <p>
                    Integrate chatbot with apps and pick from a range of
                    pre-built workflows.
                  </p>
                </div>
              </div>
              {/*How it Works Content*/}
              <div className="how_it_works_header">
                <div className="benefits-workplace-content how_it_content">
                  <img
                    src={require("../../assets/img/how-it-works/step_3.png")}
                    alt="deploy"
                  />
                  <h3>
                    <AnchorLink
                      offset={180}
                      className="url_manipulation scroll_page"
                      href="#section-3"
                    >
                      <span>03.</span>Deploy
                    </AnchorLink>
                  </h3>
                  <p>
                    Deploy chatbot on your chat channel like Teams or Slack in
                    few clicks to go live.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h6 className="how_3_steps">
            Go live in 3 steps. No coding, we promise.
          </h6>
        </div>
      </div>
    </section>
  );
}
